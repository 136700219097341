import React, { FC } from 'react';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import bigDecimal from 'js-big-decimal';

import { capitalize, showProgress } from '../../../utils/helpers';
import { CalculateMarketBuyData } from "../Calculation/CalculateBuyValues";
import { CalculateMarketSellData } from "../Calculation/CalculateSellValues";
import { MarketsTabsType } from "../../../types/enums";
import { getBackArrowIcon } from "../../../services/icons";

interface Props {
  data: any,
  tabType: string,
  currentSymbol: string,
  counterEarning: boolean,
  marketPrice?: number,
}

export const MarketsTableThirdSubRow: FC<Props> = ({
                                                     data,
                                                     tabType,
                                                     currentSymbol,
                                                     counterEarning,
                                                     marketPrice = 0,
                                                   }) => {
  const marketPriceDecimal = new bigDecimal(marketPrice);

  const isTakeProfit = data.secondTakeProfitPlacedPrice > 0 ? true : false;
  const dataFilledPrice = data.firstFilledPrice;
  const dataPrice = isTakeProfit ? bigDecimal.stripTrailingZero(bigDecimal.round(data.secondTakeProfitPlacedPrice, data.counterRound)) : bigDecimal.stripTrailingZero(bigDecimal.round(data.secondStopLossPlacedPrice, data.counterRound));

  const buyResults = CalculateMarketBuyData([data], counterEarning, marketPriceDecimal);
  const sellResults = CalculateMarketSellData([data], counterEarning, marketPriceDecimal);

  const currentSymbolValue = data.symbol.counterCurrency;
  const profitValue = tabType === MarketsTabsType.sell ? sellResults.profitTakeProfitStopLossValue : buyResults.profitTakeProfitStopLossValue;
  const profitPercentValue = tabType === MarketsTabsType.sell ? sellResults.profitPercentSecond : buyResults.profitPercentSecond;
  const averageQuantity = tabType === MarketsTabsType.sell ? sellResults.averageQuantityThird : buyResults.averageQuantityThird;
  const averageQuantityRemain = averageQuantity > 0 ? 100 - averageQuantity : 0;

  return (
    <Row>
      <Col>
        <div className='markets-table__row-main--collapsed ms-2'></div>
      </Col>
      <Col xs={9}>
        <Row className='markets-table__row subrow mt-1'>
          <Col></Col>
          <Col>
            <p style={{color: '#5b6aff'}}>{dataFilledPrice}</p>
            <p style={{color: '#ff363a'}}>{dataPrice}</p>
          </Col>
          <Col>{data.userName}</Col>
          <Col>{tabType === MarketsTabsType.sell ?
            sellResults.valueThird.compareTo(new bigDecimal(sellResults.valueFourth)) !== 0 ? sellResults.valueThird.subtract(new bigDecimal(sellResults.valueFourth)).getValue() : new bigDecimal('0').getValue() :
            buyResults.valueThird.compareTo(new bigDecimal(buyResults.valueFourth)) !== 0 ? buyResults.valueThird.subtract(new bigDecimal(buyResults.valueFourth)).getValue() : new bigDecimal('0').getValue()} {currentSymbolValue}</Col>
          {+profitValue > 0
            ? <Col
              className='text-success'>{`+${profitValue} ${!counterEarning && profitValue > data.baseQuantityStep ? currentSymbol : data.symbol.counterCurrency}`}</Col>
            : <Col
              className='text-danger'>{`${profitValue} ${!counterEarning ? data.symbol.counterCurrency : currentSymbol}`}</Col>
          }
          <Col
            className={Number(profitPercentValue) > 0 ? 'text-success' : 'text-danger'}>{(Number(profitPercentValue) > 0 ? `+${Number(profitPercentValue)}%` : `${Number(profitPercentValue)}%`)}</Col>
          <Col
            className={tabType === MarketsTabsType.buy ? 'text-success' : 'text-danger'}>{capitalize(data.section.toLowerCase())}</Col>
          <Col>
            <ProgressBar data-bs-theme='dark'>
              <ProgressBar variant="success" now={averageQuantity} label={showProgress(averageQuantity)} key={1}/>
              <ProgressBar variant="danger" now={averageQuantityRemain} label={showProgress(averageQuantityRemain)}
                           key={2}/>
            </ProgressBar>
          </Col>
          <Col>
            {getBackArrowIcon()}
          </Col>
        </Row>
      </Col>
      <Col xs={2}></Col>
    </Row>
  );
};

export default MarketsTableThirdSubRow;