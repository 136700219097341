import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack } from 'react-bootstrap';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';

import './Markets.scss';
import { CalculatorButtonType, MarketsTabsType } from '../../types/enums';
import { Calculator } from '../Calculator';
import { CustomSelect } from '../CustomSelect';
import { client } from '../../api/fetchClient';
import { AlertsListTypeContent, BalanceTypeBody, MarketsSpotType, SymbolType, } from '../../types/types';
import { capitalizeFirstLetter } from '../../utils/helpers';
import { MarketsTable } from '../MarketsTable/MarketsTable';
import { MarketsTabs } from './MarketsTabs/MarketsTabs';
import { AlertsTable } from '../AlertsTable/AlertsTable';
import useAlertSeen from '../../hooks/useAlertSeen';
import MarketPriceProvider from '../../context/MarketPriceProvider';
import useNewOrderStore from "../../services/hooks/useNewOrderStore";

export const Markets = ({marketUrl, symbolUrl, counterEarningUrl, typeUrl, section}: any) => {
  const [markets, setMarkets] = useState<string[]>([]);
  const [pairData, setPairData] = useState<SymbolType>();
  const [currentMarket, setCurrentMarket] = useState(marketUrl || 'binance');
  const [symbols, setSymbols] = useState<SymbolType[]>([]);
  const [currentSymbol, setCurrentSymbol] = useState(symbolUrl || 'XRP_USDT');
  const [alertsPrice, setAlertsPrice] = useState(0);
  const [alertExecuted, setAlertExecuted] = useState<AlertsListTypeContent>();
  const [tradeType, setTradeType] = useState(typeUrl || 'spot');
  const [tradeTypes, setTradeTypes] = useState<string[]>([]);
  const [currentTab, setCurrentTab] = useState<MarketsTabsType>(section ? section : MarketsTabsType.buy);
  const [counterEarning, setCounterEarning] = useState(counterEarningUrl !== null ? counterEarningUrl : true);
  const [counterEarningIndex, setCounterEarningIndex] = useState(counterEarning === true ? 1 : 0);
  const {marketPriceContext, setMarketPriceContext} = useContext(MarketPriceProvider);
  const {userBalance, setUserBalance} = useNewOrderStore();
  const {newOrder, removeOrder} = useNewOrderStore();

  const {setAlertsNotSeenList} = useAlertSeen();

  const websocketUrl = '/websocket-url';

  let {tradeTypeUrl, currentMarketUrl, currentSymbolUrl, currentTabUrl} = useParams();
  const navigate = useNavigate();

  const currentUrlToType = (tabValue: string | undefined) => {
    const enumValue = Object.values(MarketsTabsType).find(tab => tab === tabValue);

    if (enumValue !== undefined) {
      setCurrentTab(enumValue);
    }
  };

  useEffect(() => {
    currentUrlToType(currentTabUrl);
  }, [currentTabUrl]);

  const currentSymbolArray = currentSymbol.split('_');

  const getMarketsData = useCallback(async (url = '') => {
    try {
      const loadedData = await client.get<MarketsSpotType>('/api/markets' + url);

      if (loadedData.hasOwnProperty('error')) {
        return navigate('/markets/spot/list/');
      }

      const {markets, market, symbols, symbol, symbolPrice, tradeType, tradeTypes} = loadedData.body;

      setMarkets([...markets, 'bybit', 'coinbase']);
      setSymbols(symbols);
      // setCurrentMarket(market);
      // setCurrentSymbol(symbol.name);
      // setTradeType(tradeType.toLowerCase())
      setTradeTypes([...tradeTypes, 'futures']);
      setMarketPriceContext(symbolPrice === null ? 0 : symbolPrice);
      setPairData(symbol)
    } catch (error) {
      toast.error(`${error}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserBalance = useCallback(async (url = '') => {
    try {
      const loadedData = await client.get<BalanceTypeBody>('/api/markets' + url);
      setUserBalance(loadedData);
    } catch (error) {
      toast.error(`${error}`);
    }
  }, [setUserBalance]);

  const handleCurrentSymbolChange = async (index: number) => {

    setCounterEarningIndex(index);
    setCounterEarning(index === 1 ? true : false)

    // Fetch the market data with the updated counterEarning
    // await getMarketsData(`/${tradeType}/${currentMarket.toLocaleLowerCase()}/${currentSymbol.toLocaleLowerCase()}?counterEarning=${index === 1 ? true : false}`);

    // Navigate to the new URL
    navigate(`/markets/${tradeType.toLocaleLowerCase()}/list?market=${currentMarket.toLocaleLowerCase()}&symbolName=${currentSymbol.toLocaleLowerCase()}&counterEarning=${index === 1 ? true : false}`);
  };

  const handleSymbolsChange = async (symbol: string) => {
    setCurrentSymbol(symbol);
    setMarketPriceContext(0);
    // await getMarketsData(`/${tradeType}/${currentMarket.toLocaleLowerCase()}/${symbol.toLocaleLowerCase()}?counterEarning=${counterEarning}`);

    navigate(`/markets/${tradeType.toLocaleLowerCase()}/list?market=${currentMarket.toLocaleLowerCase()}&symbolName=${symbol.toLocaleLowerCase()}&counterEarning=${counterEarning}`);
  };

  const handleCurrentTabChange = (tab: MarketsTabsType) => {
    setCurrentTab(tab);
    navigate(`/markets/${tradeType.toLocaleLowerCase()}/list?market=${currentMarket.toLocaleLowerCase()}&symbolName=${currentSymbol.toLocaleLowerCase()}&counterEarning=${counterEarning}${(tab === 'buy' || tab === 'sell') ? `&section=${tab}` : ''}`);
    // getMarketsData(`/${tradeType}/${currentMarket.toLocaleLowerCase()}/${currentSymbol.toLocaleLowerCase()}?counterEarning=${counterEarning}${(currentTab === 'buy' || currentTab === 'sell') ? `&section=${currentTab}` : ''}`);
  };

  // useEffect(() => {
  //   getMarketsData(`/${tradeType}/${currentMarket}/${currentSymbol}?counterEarning=${counterEarning}${(currentTab === 'buy' || currentTab === 'sell') ? `&section=${currentTab}` : ''}`);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  //
  // }, [currentTab, counterEarning, currentSymbol]);

  useEffect(() => {
    getUserBalance(`/${tradeType}/symbol-quantities/${currentMarket}/${currentSymbol}`);
  }, [newOrder, removeOrder]);

  useEffect(() => {

    getMarketsData(`/${tradeType}/${currentMarket}/${currentSymbol}?counterEarning=${counterEarning}${(currentTab === 'buy' || currentTab === 'sell') ? `&section=${currentTab}` : ''}`);

    const socket = new SockJS(websocketUrl);
    const stompClient = Stomp.over(socket);
    stompClient.debug = true;
    let isConnected = false;

    const connectMarketPriceWebsocket = (stompClient: any, sessionId: string) => {
      stompClient.subscribe(`/user/${sessionId}/market-price`, (message: any) => {
        const marketMessage = JSON.parse(message.body);
        // setSymbolPrice(() => marketMessage.price);
        setMarketPriceContext(marketMessage.price);
      });
    };

    const connectFirstPositionCopyPlacedWebsocket = (stompClient: any, sessionId: string) => {
      stompClient.subscribe(`/user/${sessionId}/order-block/first-placed`, (message: any) => {
        const order = JSON.parse(message.body);

        const {addChildOrder} = useNewOrderStore.getState();

        addChildOrder(order.orderBlock.blockId, order.orderBlock);
        console.log('placed order first position');
      });
    };

    const connectFirstPositionCopyFilledWebsocket = (stompClient: any, sessionId: string) => {
      stompClient.subscribe(`/user/${sessionId}/order-block/first-filled`, (message: any) => {
        const order = JSON.parse(message.body);

        const {addFilledOrder} = useNewOrderStore.getState();
        addFilledOrder(order.orderBlock.blockId, order.orderBlock);

        console.log("filled order second position", order)

      });
    };

    const connectSecondPositionFilledTakeProfitWebsocket = (stompClient: any, sessionId: string) => {
      stompClient.subscribe(`/user/${sessionId}/order-block/second-filled-take-profit`, (message: any) => {
        const order = JSON.parse(message.body);

        const {addFilledOrder} = useNewOrderStore.getState();
        addFilledOrder(order.orderBlock.blockId, order.orderBlock);

        console.log("filled order fourth position take profit markets", order)
      });
    };

    const connectSecondPositionFilledStopLossWebsocket = (stompClient: any, sessionId: string) => {
      stompClient.subscribe(`/user/${sessionId}/order-block/second-filled-stop-loss`, (message: any) => {
        const order = JSON.parse(message.body);

        const {addFilledOrder} = useNewOrderStore.getState();
        addFilledOrder(order.orderBlock.blockId, order.orderBlock);

        console.log("filled order fourth position stop loss markets", order)
      });
    };

    const connectAlertsPriceWebsocket = (stompClient: any, sessionId: string) => {
      stompClient.subscribe(`/user/${sessionId}/BINANCE/alert-prices`, (message: any) => {
        const marketMessage = JSON.parse(message.body);
        setAlertsPrice(() => marketMessage)
      });
    };

    const connectAlertsExecutedWebsocket = (stompClient: any, sessionId: string) => {
      stompClient.subscribe(`/user/${sessionId}/BINANCE/alert-price-executed`, (message: any) => {
        const marketMessage = JSON.parse(message.body);
        setAlertExecuted(marketMessage);
      });
    };

    const handleSocketClose = () => {
      if (isConnected) {
        stompClient.disconnect(() => {
          isConnected = false;
          toast.info('Socket connection closed');
        });
      }
    };

    const websocketMarketPrice = () => {
      handleSocketClose();

      stompClient.connect({
        symbol: currentSymbol,
        market: currentMarket,
        counterEarning: counterEarning
      }, (frame: any) => {
        isConnected = true;
        const userName = frame.headers['user-name'];
        toast.success('Socket connected');

        const subscription = stompClient.subscribe(`/user/${userName}/web-id`, (message: any) => {
          try {
            console.log('Market Price Message:', message.body);
            subscription.unsubscribe();
            connectMarketPriceWebsocket(stompClient, message.body);
            connectAlertsPriceWebsocket(stompClient, message.body);
            connectAlertsExecutedWebsocket(stompClient, message.body);
            connectFirstPositionCopyPlacedWebsocket(stompClient, message.body);
            connectFirstPositionCopyFilledWebsocket(stompClient, message.body);
            connectSecondPositionFilledTakeProfitWebsocket(stompClient, message.body);
            connectSecondPositionFilledStopLossWebsocket(stompClient, message.body);
          } catch (error) {
            console.error('Error processing market price message:', error);
          }
        }, (error: any) => {
          console.error('Subscription error:', error);
        });

        stompClient.send('/app/init-web-id', {}, JSON.stringify({
          userName,
          market: currentMarket,
          symbolName: currentSymbol,
          counterEarning: counterEarning
        }));
      }, (message: any) => {
        if (message.startsWith('Whoops! Lost connection to')) {
          toast.error('Socket lost. Reload this page');
        } else {
          toast.error(`Socket lost. Error: ${message}`);
        }
      });
    };

    if (!isConnected) {
      websocketMarketPrice();
    }

    getUserBalance(`/${tradeType}/symbol-quantities/${currentMarket}/${currentSymbol}`);

    return () => {
      handleSocketClose();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [currentSymbol, counterEarning, currentTab]);

  useEffect(() => {
    const getAlertsCount = async () => {
      try {
        const loadedData = await client.get<number[]>(`/api/alert/not-seen-id-list?market=${currentMarket}`);
        setAlertsNotSeenList(loadedData);
      } catch (error) {
        toast.error(`${error}`);
      }
    };

    getAlertsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentMarket]);

  return (
    <main className='markets px-3 px-md-5 roboto-regular'>
      <Stack direction="horizontal" gap={2} className='justify-content-end justify-content-lg-between my-0 flex-wrap'>
        <div className='d-flex flex-column flex-lg-row py-3 gap-3'>
          <div className='markets__spot'>
            {tradeTypes.map((trade, index) =>
              <span key={index} className={classNames('markets__switcher', {
                active: trade.toLowerCase() === tradeType,
              })}>{capitalizeFirstLetter(trade)}</span>
            )}
          </div>

          <div className='markets__spot'>
            {markets.map((market, index) =>
              <span key={index} className={classNames('markets__switcher', {
                active: market.toLowerCase() === currentMarket,
              })}>{capitalizeFirstLetter(market)}</span>
            )}
          </div>
          <CustomSelect
            data={[
              {
                id: 1,
                name: 'Split position',
                market: '',
                minCounterQuantity: 0,
                baseCurrency: '',
                counterCurrency: '',
                baseRound: 0,
                counterRound: 0,
                simpleName: ''
              },
              {
                id: 2,
                name: 'Choose average',
                market: '',
                minCounterQuantity: 0,
                baseCurrency: '',
                counterCurrency: '',
                baseRound: 0,
                counterRound: 0,
                simpleName: ''
              },
              {
                id: 3,
                name: 'Average All',
                market: '',
                minCounterQuantity: 0,
                baseCurrency: '',
                counterCurrency: '',
                baseRound: 0,
                counterRound: 0,
                simpleName: ''
              }]}
            title={'Average '}
            classStyle='markets__dropdown'
          /><CustomSelect
          data={symbols}
          title={currentSymbol}
          handler={handleSymbolsChange}
          classStyle='markets__dropdown text-uppercase'
          isSymbols
        />
        </div>
      </Stack>

      <div className='d-flex gap-3 flex-wrap flex-lg-row flex-column-reverse'>
        <Calculator
          currency={currentSymbolArray}
          type={CalculatorButtonType.buy}
          balance={userBalance?.counterQuantity}
          balanceCurrency={userBalance?.marketSymbol?.counterCurrency}
          currencyPairData={pairData}
          counterEarning={counterEarning}
          onSubmit={() => handleCurrentTabChange(MarketsTabsType.buy)}
        />
        <Calculator
          currency={currentSymbolArray}
          type={CalculatorButtonType.sell}
          balance={userBalance?.quantity}
          balanceCurrency={userBalance?.marketSymbol?.baseCurrency}
          currencyPairData={pairData}
          counterEarning={counterEarning}
          onSubmit={() => handleCurrentTabChange(MarketsTabsType.sell)}
        />

        <Stack direction="vertical" gap={3} className='markets__blocks'>
          <div className='markets__marketprice earn'>
            Choose your earn:

            <ul className='markets__options'>
              {currentSymbolArray.map((symbol: any, index: any) =>
                <li key={symbol} className={classNames('markets__switcher  text-uppercase', {
                  active: counterEarningIndex === index,
                })} onClick={() => handleCurrentSymbolChange(index)}>{symbol}</li>
              )}
            </ul>

          </div>

          <div className='markets__marketprice'>
            Market price:
            <p className='markets__marketprice-value'>{marketPriceContext}</p>
          </div>
        </Stack>
      </div>

      <MarketsTabs currentTab={currentTab} tabChange={handleCurrentTabChange}/>

      {(currentTab === MarketsTabsType.buy || currentTab === MarketsTabsType.sell || currentTab === MarketsTabsType.all || currentTab === MarketsTabsType.history) &&
          <MarketsTable
              tabType={currentTab}
              counterEarning={counterEarning}
              tradeType={tradeType}
              currentMarket={currentMarket}
              currentSymbol={currentSymbol}
          />
      }

      {currentTab === MarketsTabsType.alerts &&
          <AlertsTable alertsPrice={alertsPrice} currentMarket={currentMarket} alertExecuted={alertExecuted}/>
      }
    </main>
  );
};