import React, { FC, useEffect, useId, useState } from 'react';
import classNames from 'classnames';
import { Col, Collapse, ProgressBar, Row, Stack } from 'react-bootstrap';
import bigDecimal from 'js-big-decimal';

import { capitalize, milisecondsToDate, showProgress } from '../../../utils/helpers';
import { MarketsTabsType } from '../../../types/enums';
import { MarketsTableInput } from '../MarketsTableInput/MarketsTableInput';
import { ModalProfitLoss } from "../../Modals/ModalProfitLoss/ModalProfitLoss";
import { MarketsTableThirdSubRow } from "../MarketsTableSubRow/MarketsTableThirdSubRow";
import { getBackArrowIcon } from "../../../services/icons";
import useNewOrderStore from "../../../services/hooks/useNewOrderStore";
import { toast } from "react-toastify";

interface Props {
  data: any,
  counterEarning: boolean,
  tabType: MarketsTabsType,
  marketPrice: number,
  averageQuantity?: number,
  profitValue?: number,
  profitPercent: string,
  value: string,
  isFilled?: boolean,
  isRed?: boolean
  show?: boolean
  groupData?: any,
}

export const MarketsTableRowThirdPosition: FC<Props> = ({
                                                          data,
                                                          counterEarning,
                                                          tabType,
                                                          marketPrice,
                                                          averageQuantity = 0,
                                                          profitValue = 0,
                                                          profitPercent = 0,
                                                          value,
                                                          isFilled = false,
                                                          isRed = false,
                                                          show,
                                                          groupData,
                                                        }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const [type, setType] = useState<string>();
  const [stopLoss, setStopLoss] = useState<string>(data?.secondStopLossPlacedPrice || "");
  const [takeProfit, setTakeProfit] = useState<string>(data?.secondTakeProfitPlacedPrice || "");

  const collapseId = useId();
  const handleShowModal = () => setShowModal(true);

  const tP = tabType === 'sell' ? bigDecimal.stripTrailingZero(bigDecimal.round(marketPrice - data.symbol.counterStepSize * 3, data.counterRound)) : bigDecimal.stripTrailingZero(bigDecimal.round(marketPrice + data.symbol.counterStepSize * 3, data.counterRound))
  const sL = tabType === 'sell' ? bigDecimal.stripTrailingZero(bigDecimal.round(marketPrice + data.symbol.counterStepSize * 3, data.counterRound)) : bigDecimal.stripTrailingZero(bigDecimal.round(marketPrice - data.symbol.counterStepSize * 3, data.counterRound))

  const handleSendData = ({newValue, focused, type}: {
    newValue: string | number,
    focused: boolean,
    type?: string
  }) => {
    setIsFocused(focused);
    setType(type);
  };

  const dateFilledTime = milisecondsToDate(data.firstFilledTime);
  const dateTime = data.secondTakeProfitPlacedTime ? milisecondsToDate(data.secondTakeProfitPlacedTime) : milisecondsToDate(data.secondStopLossPlacedTime);
  const dataFilledPrice = data.firstFilledPrice;
  const dataPrice = data.secondTakeProfitPlacedPrice ? bigDecimal.stripTrailingZero(bigDecimal.round(data.secondTakeProfitPlacedPrice, data.symbol.counterRound)) : bigDecimal.stripTrailingZero(bigDecimal.round(data.secondStopLossPlacedPrice, data.symbol.counterRound));
  const currentSymbol = counterEarning ? data.symbol.counterCurrency : data.symbol.baseCurrency;
  const currentSymbolValue = data.symbol.counterCurrency;
  const averageQuantityRemain = averageQuantity > 0 ? 100 - averageQuantity : 0;

  function handleKeyDownSL(event: any) {
    if (event.keyCode === 13 && event.target.value) {
      const id = toast.loading("Please wait...", {closeButton: true});
      const newStopLoss = event.target.value;

      const sendValue = {
        market: "binance",
        symbolName: data.symbol.name,
        blockId: data.blockId,
        stopLossPrice: Number(newStopLoss),
        takeProfitPrice: takeProfit ? Number(takeProfit) : 0,
      };

      const method = takeProfit && stopLoss ? 'PUT' : takeProfit || stopLoss ? 'PUT' : 'POST';

      fetch('/api/markets/spot/third', {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(sendValue),
      })
        .then((response) => response.json())
        .then((responseData) => {
          console.log('Response Body:', responseData);

          if (responseData.errorMessage === null) {

            const updateOrderData = {
              baseQuantityStep: responseData.orderBlocks[0].symbol.baseQuantityStep,
              baseRound: 0,
              blockId: responseData.orderBlocks[0].blockId,
              counterEarning: responseData.orderBlocks[0].counterEarning,
              counterRound: responseData.orderBlocks[0].symbol.counterRound,
              firstFilledCounterQuantity: Number(new bigDecimal(responseData.orderBlocks[0].firstFilledQuantity * responseData.orderBlocks[0].firstFilledPrice).round(data.counterRound, bigDecimal.RoundingModes.CEILING).getValue()),
              firstFilledPrice: responseData.orderBlocks[0].firstFilledPrice,
              firstFilledQuantity: responseData.orderBlocks[0].firstFilledQuantity,
              firstFilledTime: responseData.orderBlocks[0].firstFilledTime,
              firstFilledUnrealizedQuantity: responseData.orderBlocks[0].firstFilledUnrealizedQuantity,
              firstOrderId: responseData.orderBlocks[0].firstOrderId,
              firstPlacedPrice: responseData.orderBlocks[0].firstPlacedPrice,
              firstPlacedQuantity: responseData.orderBlocks[0].firstPlacedQuantity,
              firstPlacedTime: responseData.orderBlocks[0].firstPlacedTime,
              open: responseData.orderBlocks[0].open,
              secondFirstStopLossFilledCounterQuantity: 0,
              secondFirstStopLossPlacedCounterQuantity: 0,
              secondFirstStopLossPlacedQuantity: responseData.orderBlocks[0].secondFirstStopLossPlacedQuantity,
              secondFirstTakeProfitFilledCounterQuantity: 0,
              secondFirstTakeProfitPlacedCounterQuantity: 0,
              secondFirstTakeProfitPlacedQuantity: responseData.orderBlocks[0].secondFirstTakeProfitPlacedQuantity,
              secondStopLossFilledCounterQuantity: 0,
              secondStopLossFilledPrice: 0,
              secondStopLossFilledQuantity: 0,
              secondStopLossFilledTime: 0,
              secondStopLossOrderId: 0,
              secondStopLossPlacedCounterQuantity: 0,
              secondStopLossPlacedPrice: responseData.orderBlocks[0].secondStopLossPlacedPrice ? responseData.orderBlocks[0].secondStopLossPlacedPrice : 0,
              secondStopLossPlacedQuantity: responseData.orderBlocks[0].secondStopLossPlacedQuantity ? responseData.orderBlocks[0].secondStopLossPlacedQuantity : 0,
              secondStopLossPlacedTime: responseData.orderBlocks[0].secondStopLossPlacedTime ? responseData.orderBlocks[0].secondStopLossPlacedTime : 0,
              secondTakeProfitFilledCounterQuantity: 0,
              secondTakeProfitFilledPrice: 0,
              secondTakeProfitFilledQuantity: 0,
              secondTakeProfitFilledTime: 0,
              secondTakeProfitOrderId: 0,
              secondTakeProfitPlacedCounterQuantity: 0,
              secondTakeProfitPlacedPrice: responseData.orderBlocks[0].secondTakeProfitPlacedPrice ? responseData.orderBlocks[0].secondTakeProfitPlacedPrice : 0,
              secondTakeProfitPlacedQuantity: responseData.orderBlocks[0].secondTakeProfitPlacedQuantity ? responseData.orderBlocks[0].secondTakeProfitPlacedQuantity : 0,
              secondTakeProfitPlacedTime: responseData.orderBlocks[0].secondTakeProfitPlacedTime ? responseData.orderBlocks[0].secondTakeProfitPlacedTime : 0,
              section: responseData.orderBlocks[0].section,
              userName: responseData.orderBlocks[0].userName,
              symbol: {
                name: responseData.orderBlocks[0].symbol.name,
                market: responseData.orderBlocks[0].symbol.market,
                baseStepSize: responseData.orderBlocks[0].symbol.baseStepSize,
                counterStepSize: responseData.orderBlocks[0].symbol.counterStepSize,
                minCounterQuantity: responseData.orderBlocks[0].symbol.minCounterQuantity,
                baseCurrency: responseData.orderBlocks[0].symbol.baseCurrency,
                counterCurrency: responseData.orderBlocks[0].symbol.counterCurrency,
                baseRound: responseData.orderBlocks[0].symbol.baseRound,
                counterRound: responseData.orderBlocks[0].symbol.counterRound,
                baseQuantityStep: responseData.orderBlocks[0].symbol.baseQuantityStep,
              }
            };

            const {addFilledOrder} = useNewOrderStore.getState();
            addFilledOrder(responseData.orderBlocks[0].blockId, updateOrderData);

            setStopLoss(responseData.stopLossPrice || newStopLoss);
            toast.update(id, {
              render: 'Stop loss successfully placed',
              type: 'success',
              autoClose: 5000,
              isLoading: false
            });
          } else {
            setError(true);
            setStopLoss(data?.secondStopLossPlacedPrice || "");
            toast.update(id, {
              render: responseData.errorMessage,
              type: 'warning',
              autoClose: 5000,
              isLoading: false
            });
          }

        })
        .catch((error) => {
          console.error('Network error:', error)
          toast.update(id, {
            render: error.message,
            type: 'warning',
            autoClose: 5000,
            isLoading: false
          });
        });
    }
  }

  function handleKeyDownTP(event: any) {
    if (event.keyCode === 13 && event.target.value) {
      const id = toast.loading("Please wait...", {closeButton: true});
      const newTakeProfit = event.target.value;

      const sendValue = {
        market: "binance",
        symbolName: data.symbol.name,
        blockId: data.blockId,
        takeProfitPrice: Number(newTakeProfit),
        stopLossPrice: stopLoss ? stopLoss : 0,
      };

      const method = takeProfit && stopLoss ? 'PUT' : stopLoss || takeProfit ? 'PUT' : 'POST';
      console.log(sendValue);
      console.log(method);
      fetch('/api/markets/spot/third', {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(sendValue),
      })
        .then((response) => response.json())
        .then((responseData) => {
          console.log('Response Body:', responseData);

          if (responseData.errorMessage === null) {

            const updateOrderData = {
              baseQuantityStep: responseData.orderBlocks[0].symbol.baseQuantityStep,
              baseRound: 0,
              blockId: responseData.orderBlocks[0].blockId,
              counterEarning: responseData.orderBlocks[0].counterEarning,
              counterRound: responseData.orderBlocks[0].symbol.counterRound,
              firstFilledCounterQuantity: Number(new bigDecimal(responseData.orderBlocks[0].firstFilledQuantity * responseData.orderBlocks[0].firstFilledPrice).round(data.counterRound, bigDecimal.RoundingModes.CEILING).getValue()),
              firstFilledPrice: responseData.orderBlocks[0].firstFilledPrice,
              firstFilledQuantity: responseData.orderBlocks[0].firstFilledQuantity,
              firstFilledTime: responseData.orderBlocks[0].firstFilledTime,
              firstFilledUnrealizedQuantity: responseData.orderBlocks[0].firstFilledUnrealizedQuantity,
              firstOrderId: responseData.orderBlocks[0].firstOrderId,
              firstPlacedPrice: responseData.orderBlocks[0].firstPlacedPrice,
              firstPlacedQuantity: responseData.orderBlocks[0].firstPlacedQuantity,
              firstPlacedTime: responseData.orderBlocks[0].firstPlacedTime,
              open: responseData.orderBlocks[0].open,
              secondFirstStopLossFilledCounterQuantity: 0,
              secondFirstStopLossPlacedCounterQuantity: 0,
              secondFirstStopLossPlacedQuantity: responseData.orderBlocks[0].secondFirstStopLossPlacedQuantity,
              secondFirstTakeProfitFilledCounterQuantity: 0,
              secondFirstTakeProfitPlacedCounterQuantity: 0,
              secondFirstTakeProfitPlacedQuantity: responseData.orderBlocks[0].secondFirstTakeProfitPlacedQuantity,
              secondStopLossFilledCounterQuantity: 0,
              secondStopLossFilledPrice: 0,
              secondStopLossFilledQuantity: 0,
              secondStopLossFilledTime: 0,
              secondStopLossOrderId: 0,
              secondStopLossPlacedCounterQuantity: 0,
              secondStopLossPlacedPrice: responseData.orderBlocks[0].secondStopLossPlacedPrice ? responseData.orderBlocks[0].secondStopLossPlacedPrice : 0,
              secondStopLossPlacedQuantity: responseData.orderBlocks[0].secondStopLossPlacedQuantity ? responseData.orderBlocks[0].secondStopLossPlacedQuantity : 0,
              secondStopLossPlacedTime: responseData.orderBlocks[0].secondStopLossPlacedTime ? responseData.orderBlocks[0].secondStopLossPlacedTime : 0,
              secondTakeProfitFilledCounterQuantity: 0,
              secondTakeProfitFilledPrice: 0,
              secondTakeProfitFilledQuantity: 0,
              secondTakeProfitFilledTime: 0,
              secondTakeProfitOrderId: 0,
              secondTakeProfitPlacedCounterQuantity: 0,
              secondTakeProfitPlacedPrice: responseData.orderBlocks[0].secondTakeProfitPlacedPrice,
              secondTakeProfitPlacedQuantity: responseData.orderBlocks[0].secondTakeProfitPlacedQuantity,
              secondTakeProfitPlacedTime: responseData.orderBlocks[0].secondTakeProfitPlacedTime,
              section: responseData.orderBlocks[0].section,
              userName: responseData.orderBlocks[0].userName,
              symbol: {
                name: responseData.orderBlocks[0].symbol.name,
                market: responseData.orderBlocks[0].symbol.market,
                baseStepSize: responseData.orderBlocks[0].symbol.baseStepSize,
                counterStepSize: responseData.orderBlocks[0].symbol.counterStepSize,
                minCounterQuantity: responseData.orderBlocks[0].symbol.minCounterQuantity,
                baseCurrency: responseData.orderBlocks[0].symbol.baseCurrency,
                counterCurrency: responseData.orderBlocks[0].symbol.counterCurrency,
                baseRound: responseData.orderBlocks[0].symbol.baseRound,
                counterRound: responseData.orderBlocks[0].symbol.counterRound,
                baseQuantityStep: responseData.orderBlocks[0].symbol.baseQuantityStep,
              }
            };

            const {addFilledOrder} = useNewOrderStore.getState();
            addFilledOrder(responseData.orderBlocks[0].blockId, updateOrderData);

            setTakeProfit(responseData.takeProfitPrice || newTakeProfit);

            toast.update(id, {
              render: 'Take profit successfully placed',
              type: 'success',
              autoClose: 5000,
              isLoading: false
            });
          } else {
            setTakeProfit(data?.secondTakeProfitPlacedPrice || "");
            setError(true);
            toast.update(id, {
              render: responseData.errorMessage,
              type: 'warning',
              autoClose: 5000,
              isLoading: false
            });
          }
        })
        .catch((error) => {
          console.error('Network error:', error)
          toast.update(id, {
            render: error.message,
            type: 'warning',
            autoClose: 5000,
            isLoading: false
          });
        });
    }
  }

  const handleReset = () => {
    setError(false);
  };

  const removePosition = async () => {
    const id = toast.loading("Please wait...", {closeButton: true});
    const sendValue = {
      market: "binance",
      symbolName: data.symbol.name,
      blockId: data.blockId,
    };

    const method = 'DELETE';

    fetch('/api/markets/spot/third', {
      method: method,
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sendValue),
    })
      .then((response) => response.json())
      .then((responseData) => {
        // const {setRemoveOrder} = useNewOrderStore.getState();
        // setRemoveOrder(data.blockId);
        console.log('Response Body:', responseData);

        if (responseData.errorMessage === null) {
          const updateOrderData = {
            baseQuantityStep: data.symbol.baseQuantityStep,
            baseRound: 0,
            blockId: data.blockId,
            counterEarning: data.counterEarning,
            counterRound: data.symbol.counterRound,
            firstFilledCounterQuantity: Number(new bigDecimal(data.firstFilledQuantity * dataFilledPrice).round(data.counterRound, bigDecimal.RoundingModes.CEILING).getValue()),
            firstFilledPrice: data.firstFilledPrice,
            firstFilledQuantity: data.firstFilledQuantity,
            firstFilledTime: data.firstFilledTime,
            firstFilledUnrealizedQuantity: data.firstFilledUnrealizedQuantity,
            firstOrderId: data.firstOrderId,
            firstPlacedPrice: data.firstPlacedPrice,
            firstPlacedQuantity: data.firstPlacedQuantity,
            firstPlacedTime: data.firstPlacedTime,
            open: data.open,
            secondFirstStopLossFilledCounterQuantity: 0,
            secondFirstStopLossPlacedCounterQuantity: 0,
            secondFirstStopLossPlacedQuantity: 0,
            secondFirstTakeProfitFilledCounterQuantity: 0,
            secondFirstTakeProfitPlacedCounterQuantity: 0,
            secondFirstTakeProfitPlacedQuantity: 0,
            secondStopLossFilledCounterQuantity: 0,
            secondStopLossFilledPrice: 0,
            secondStopLossFilledQuantity: 0,
            secondStopLossFilledTime: 0,
            secondStopLossOrderId: 0,
            secondStopLossPlacedCounterQuantity: 0,
            secondStopLossPlacedPrice: 0,
            secondStopLossPlacedQuantity: 0,
            secondStopLossPlacedTime: 0,
            secondTakeProfitFilledCounterQuantity: 0,
            secondTakeProfitFilledPrice: 0,
            secondTakeProfitFilledQuantity: 0,
            secondTakeProfitFilledTime: 0,
            secondTakeProfitOrderId: 0,
            secondTakeProfitPlacedCounterQuantity: 0,
            secondTakeProfitPlacedPrice: 0,
            secondTakeProfitPlacedQuantity: 0,
            secondTakeProfitPlacedTime: 0,
            section: data.section,
            userName: data.userName,
            symbol: {
              name: data.symbol.name,
              market: data.symbol.market,
              baseStepSize: data.symbol.baseStepSize,
              counterStepSize: data.symbol.counterStepSize,
              minCounterQuantity: data.symbol.minCounterQuantity,
              baseCurrency: data.symbol.baseCurrency,
              counterCurrency: data.symbol.counterCurrency,
              baseRound: data.symbol.baseRound,
              counterRound: data.symbol.counterRound,
              baseQuantityStep: data.symbol.baseQuantityStep,
            }
          };

          const {addFilledOrder} = useNewOrderStore.getState();
          addFilledOrder(data.blockId, updateOrderData);

          toast.update(id, {
            render: 'Take profit/stop loss successfully removed',
            type: 'success',
            autoClose: 5000,
            isLoading: false
          });
        } else {

          toast.update(id, {
            render: responseData.errorMessage,
            type: 'warning',
            autoClose: 5000,
            isLoading: false
          });
        }
      })
      .catch((error) => console.error('Network error:', error));
  }

  useEffect(() => {
    setTakeProfit(data?.secondTakeProfitPlacedPrice || "");
  }, [data?.secondTakeProfitPlacedPrice]);

  useEffect(() => {
    setStopLoss(data?.secondStopLossPlacedPrice || "");
  }, [data?.secondStopLossPlacedPrice]);

  return (
    <div style={{border: isRed ? '1px solid red' : ''}} className={show ? "" : "d-none"}>
      <Row className='align-items-center justify-content-center fw-bold mt-2'>
        <Col
          onClick={groupData.length > 0 ? () => setOpen(!open) : undefined}
          aria-controls={groupData.length > 0 ? collapseId : ''}
          aria-expanded={groupData.length > 0 ? open : undefined}
          style={groupData.length > 0 ? {cursor: 'pointer'} : {cursor: 'default'}}
          className='ms-md-2 my-3 my-md-0'
          xs={3} md
        >
          <div className={classNames('markets-table__row-main', {open})}>
            <div className={classNames('markets-table__status', {
              'markets-table__status--dark-blue': (tabType === MarketsTabsType.buy || tabType === MarketsTabsType.all) && isFilled === false,
              'markets-table__status--yellow': tabType === MarketsTabsType.sell,
              'three': groupData.length > 0,
              'empty': groupData.length === 0,
            })}/>
          </div>
        </Col>
        <Col md={9} className='mb-3 mb-md-0'>
          <Row className='markets-table__row py-1'>
            <Col xs={4} md className='my-1 my-md-0'>
              <p style={{color: '#8997dc'}}>{dateFilledTime}</p>
              <p>{dateTime}</p>
            </Col>
            <Col xs={4} md className='my-1 my-md-0'>
              <p style={{color: '#5b6aff'}}>{dataFilledPrice}</p>
              <p style={{color: '#ff363a'}}>{dataPrice}</p>
            </Col>
            <Col xs={4} md className='my-1 my-md-0'>{data.symbol.name}</Col>
            <Col xs={4} md className='my-1 my-md-0'>{`${Number(value)} ${currentSymbolValue}`}</Col>
            {tabType === 'buy'
              ? <Col
                xs={4} md
                className={`${profitValue > 0 ? 'text-success ' : 'text-danger '} my-1 my-md-0`}>{`${profitValue > 0 ? '+' + profitValue : profitValue} 
                ${!counterEarning && profitValue >= data.baseQuantityStep ?
                data.symbol.baseCurrency :
                data.symbol.counterCurrency}`}
              </Col>
              : <Col
                xs={4} md
                className={`${profitValue > 0 ? 'text-success ' : 'text-danger '} my-1 my-md-0`}>{`${profitValue}
                ${!counterEarning ?
                profitValue > 0 && profitValue < data.baseQuantityStep ? data.symbol.counterCurrency : data.symbol.baseCurrency :
                profitValue > 0 ? data.symbol.counterCurrency : data.symbol.baseCurrency}`}
              </Col>
            }
            {+Number(profitPercent) > 0
              ? <Col xs={4} md className='text-success my-1 my-md-0'>{`+${Number(profitPercent)}%`}</Col>
              : <Col xs={4} md className='text-danger my-1 my-md-0'>{`${Number(profitPercent)}%`}</Col>
            }
            <Col xs={4} md
                 className={`${tabType === 'buy' ? 'text-success my-1 my-md-0' : 'text-danger my-1 my-md-0'}`}>{capitalize(tabType)}</Col>
            <Col xs={4} md className='my-1 my-md-0'>
              <ProgressBar data-bs-theme='dark'>
                <ProgressBar variant="success" now={averageQuantity}
                             label={showProgress(averageQuantity)} key={1}/>
                <ProgressBar variant="danger" now={averageQuantityRemain} label={showProgress(averageQuantityRemain)}
                             key={2}/>
              </ProgressBar>
            </Col>
            <Col xs={4} md className='my-1 my-md-0'>
              <button type="button" className="" style={{backgroundColor: "transparent"}} onClick={removePosition}>
                {getBackArrowIcon()}
              </button>
            </Col>
          </Row>
        </Col>
        <Col md={2}>
          <Stack direction="horizontal" className='markets-table__inputwrapper'>
            <MarketsTableInput
              placeHolder={'S.L.'}
              name='sl'
              counterRound={data.counterRound}
              inputValue={stopLoss}
              resetValue={error ? stopLoss : undefined}
              onReset={handleReset}
              onKeyDown={handleKeyDownSL}
              sendData={handleSendData}
            />
            <span style={{color: '#7783c0'}}>/</span>
            <MarketsTableInput
              placeHolder={'T.P.'}
              name='tp'
              counterRound={data.counterRound}
              inputValue={takeProfit}
              resetValue={error ? takeProfit : undefined}
              onReset={handleReset}
              onKeyDown={handleKeyDownTP}
              sendData={handleSendData}
            />
            <button className='markets-table__button' onClick={handleShowModal}>
              More
            </button>
          </Stack>
          {isFocused && <div
              className="markets-table__error position-absolute">{type === "tp" ? `${tabType === "buy" ? 'Min ' : 'Max '}take profit is ${tP}` : `${tabType === "buy" ? 'Max ' : 'Min '} stop loss is ${sL}`} </div>}
        </Col>
      </Row>

      {groupData.length > 0 && groupData.map((subRow: any, index: number) => {
        return (
          <Collapse in={open} key={index}>
            <div id={collapseId}>
              <MarketsTableThirdSubRow
                key={index}
                data={subRow}
                tabType={subRow.section.toLowerCase()}
                currentSymbol={currentSymbol}
                counterEarning={counterEarning}
                marketPrice={marketPrice}
              />
            </div>
          </Collapse>
        )
      })}

      {showModal && <ModalProfitLoss
          onClose={() => setShowModal(false)}
          show={showModal}
          type={tabType}
          coinPair={data.symbol.name}
          entryPrice={dataFilledPrice}
          symbol={currentSymbol}
          baseSymbol={data.symbol.baseCurrency}
          dataFilledPrice={dataFilledPrice}
          filledCounterQuantity={data.firstFilledQuantity}
          counterQuantity={Number(value)}
          currentSymbolValue={currentSymbolValue}
          marketPrice={marketPrice}
          counterRound={data.counterRound}
          baseRound={data.baseRound}
          counterStep={data.symbol.counterStepSize}
          baseStep={data.symbol.baseStepSize}
          tp={takeProfit}
          sl={stopLoss}
          id={data.blockId}
          counterEarning={counterEarning}
      />}
    </div>
  );
};