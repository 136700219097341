import { useEffect, useState } from 'react';
import bigDecimal from 'js-big-decimal';
import { takeAverage } from "../../../utils/helpers";

interface MarketData {
  symbol: {
    counterRound: number;
    baseStepSize: number;
    counterStepSize: number
  };
  blockId: number;
  firstPlacedQuantity: string;
  firstPlacedCounterQuantity: string;
  firstFilledCounterQuantity: string;
  firstFilledQuantity: string;
  takeProfitStopLossCounterQuantity: string;
  filledTakeProfitStopLossQuantity: string;
  buyFilledTakeProfitStopLossCounterQuantity: string;
  buyTakeProfitStopLossCounterQuantity: string;
  filledUnrealizedQuantity: string;
  secondFilledPrice: string;
  firstSecondPlacedQuantity: string;
  firstFilledPrice: string;
  firstPlacedPrice: string;
  baseRound: number;
  baseQuantityStep: number;
  counterRound: number;
  secondTakeProfitPlacedPrice: number;
  secondStopLossPlacedPrice: number;
  secondFirstTakeProfitPlacedQuantity: string;
  secondFirstStopLossPlacedQuantity: string;
  secondTakeProfitFilledPrice: number;
  secondStopLossFilledPrice: number;
}

interface CalculateMarketDataResult {
  valueFirst: bigDecimal;
  valueSecond: bigDecimal;
  valueThird: bigDecimal;
  valueFourth: string;
  averageQuantitySecond: number;
  profitQuantity: bigDecimal;
  profitPercentSecond: string;
  averageQuantityThird: number;
  profitTakeProfitStopLossQuantity: bigDecimal;
  profitTakeProfitStopLossValue: number;
  profitTakeProfitStopLossPercent: bigDecimal;
  averageQuantityFourth: number;
  profitFilledTakeProfitStopLossQuantity: number;
  profitFilledTakeProfitStopLossValue: number;
  profitFilledTakeProfitStopLossValuePercent: bigDecimal;
  resultSumFilledTakeProfitStopLossQuantityAdditional: bigDecimal;
  showFirstPosition: boolean;
  showSecondPosition: boolean;
  showThirdPosition: boolean;
  showFourthPosition: boolean;
  showCssLine: boolean;
}

export function CalculateMarketSellData(
  data: MarketData[],
  counterEarning: boolean,
  marketPriceDecimal: bigDecimal,
): CalculateMarketDataResult {

  const [dataTakeProfitStopLossQuantity, setDataTakeProfitStopLossQuantity] = useState('');
  const [dataFilledTakeProfitStopLossQuantity, setDataFilledTakeProfitStopLossQuantity] = useState('');
  const [dataBuyFilledTakeProfitStopLossCounterQuantity, setDataBuyFilledTakeProfitStopLossCounterQuantity] = useState('');
  const [dataSecondFilledPrice, setDataSecondFilledPrice] = useState('');
  const [dataFirstSecondPlacedQuantity, setDataFirstSecondPlacedQuantity] = useState('');
  const dataQuantityStable = 'firstPlacedQuantity';
  const dataFilledPrice = 'firstFilledPrice';
  const dataCounterQuantity = 'firstPlacedCounterQuantity';
  const dataFilledCounterQuantity = 'firstFilledCounterQuantity';
  const dataFilledQuantityStable = 'firstFilledQuantity';
  const dataFilledUnrealizedQuantity = 'firstFilledUnrealizedQuantity';
  const bigDecimalZero = new bigDecimal('0');

  const {
    sumQuantityStable,
    sumFilledPrice,
    sumCounterQuantity,
    sumFilledCounterQuantityStable,
    sumFilledQuantityStable,
    sumTakeProfitStopLossQuantity,
    sumFilledTakeProfitStopLossQuantity,
    sumBuyFilledTakeProfitStopLossCounterQuantity,
    sumFilledUnrealizedQuantity,
    sumSecondFilledPrice,
    sumFirstSecondPlacedQuantity,

  } = data.reduce((acc, item) => {
    const quantityStable = new bigDecimal(item[dataQuantityStable as keyof MarketData] as string);
    const quantityFilledPrice = new bigDecimal(item[dataFilledPrice as keyof MarketData] as string);
    const counterQuantity = new bigDecimal(item[dataCounterQuantity as keyof MarketData] as string);
    const filledCounterQuantity = new bigDecimal(item[dataFilledCounterQuantity as keyof MarketData] as string);
    const filledQuantityStable = new bigDecimal(item[dataFilledQuantityStable as keyof MarketData] as string);
    const takeProfitStopLossQuantity = new bigDecimal(item[dataTakeProfitStopLossQuantity as keyof MarketData] as string);
    const takeFilledProfitStopLossQuantity = new bigDecimal(item[dataFilledTakeProfitStopLossQuantity as keyof MarketData] as string);
    const buyFilledTakeProfitStopLossCounterQuantity = new bigDecimal(item[dataBuyFilledTakeProfitStopLossCounterQuantity as keyof MarketData] as string);
    const filledUnrealizedQuantity = new bigDecimal(item[dataFilledUnrealizedQuantity as keyof MarketData] as string);
    const secondFilledPrice = new bigDecimal(item[dataSecondFilledPrice as keyof MarketData] as string);
    const firstSecondPlacedQuantity = new bigDecimal(item[dataFirstSecondPlacedQuantity as keyof MarketData] as string);

    return {
      sumQuantityStable: acc.sumQuantityStable.add(quantityStable),
      sumFilledPrice: acc.sumFilledPrice.add(quantityFilledPrice),
      sumCounterQuantity: acc.sumCounterQuantity.add(counterQuantity),
      sumFilledCounterQuantityStable: acc.sumFilledCounterQuantityStable.add(filledCounterQuantity),
      sumFilledQuantityStable: acc.sumFilledQuantityStable.add(filledQuantityStable),
      sumTakeProfitStopLossQuantity: acc.sumTakeProfitStopLossQuantity.add(takeProfitStopLossQuantity),
      sumFilledTakeProfitStopLossQuantity: acc.sumFilledTakeProfitStopLossQuantity.add(takeFilledProfitStopLossQuantity),
      sumBuyFilledTakeProfitStopLossCounterQuantity: acc.sumBuyFilledTakeProfitStopLossCounterQuantity.add(buyFilledTakeProfitStopLossCounterQuantity),
      sumFilledUnrealizedQuantity: acc.sumFilledUnrealizedQuantity.add(filledUnrealizedQuantity),
      sumSecondFilledPrice: acc.sumSecondFilledPrice.add(secondFilledPrice),
      sumFirstSecondPlacedQuantity: acc.sumFirstSecondPlacedQuantity.add(firstSecondPlacedQuantity),
    };
  }, {
    sumQuantityStable: bigDecimalZero,
    sumFilledPrice: bigDecimalZero,
    sumCounterQuantity: bigDecimalZero,
    sumFilledCounterQuantityStable: bigDecimalZero,
    sumFilledQuantityStable: bigDecimalZero,
    sumTakeProfitStopLossQuantity: bigDecimalZero,
    sumFilledTakeProfitStopLossQuantity: bigDecimalZero,
    sumBuyFilledTakeProfitStopLossCounterQuantity: bigDecimalZero,
    sumFilledUnrealizedQuantity: bigDecimalZero,
    sumSecondFilledPrice: bigDecimalZero,
    sumFirstSecondPlacedQuantity: bigDecimalZero,
  });

  const valueThird = counterEarning ? sumFirstSecondPlacedQuantity.multiply(sumFilledPrice) : sumFirstSecondPlacedQuantity.multiply(sumFilledPrice);

  const valueSecond = Number(sumFilledUnrealizedQuantity.getValue()) !== 0
    ? sumFilledUnrealizedQuantity.multiply(sumFilledPrice)
    : sumFilledQuantityStable.multiply(sumFilledPrice);


  const valueFirst = valueThird.compareTo(valueSecond) !== 0
    ? sumQuantityStable.multiply(new bigDecimal(data[0].firstPlacedPrice)).subtract(valueSecond).subtract(valueThird)
    : sumQuantityStable.multiply(new bigDecimal(data[0].firstPlacedPrice)).subtract(valueSecond);


  const valueFourth = counterEarning
    ? sumFilledTakeProfitStopLossQuantity
      .multiply(sumFilledPrice)
      .getValue() :
    sumTakeProfitStopLossQuantity.compareTo(sumFilledTakeProfitStopLossQuantity) !== 0 ?
      sumFilledTakeProfitStopLossQuantity
        .multiply(sumSecondFilledPrice)
        .divide(new bigDecimal(data[0].firstFilledPrice))
        .round(data[0].baseRound, bigDecimal.RoundingModes.UP)
        .multiply(new bigDecimal(data[0].firstFilledPrice))
        .getValue()
      : sumFilledTakeProfitStopLossQuantity
        .multiply(new bigDecimal(data[0].firstFilledPrice))
        .getValue();

  // calculate 1-st position
  const sumQuantity = counterEarning ? sumCounterQuantity : sumQuantityStable;

  // calculate 2-nd position
  const averageQuantitySecond = Number(sumQuantityStable.getValue()) === 0
    ? 0
    :
    counterEarning ?
      takeAverage(
        Number(sumFilledUnrealizedQuantity.getValue()) > 0
          ? sumFilledUnrealizedQuantity.add(sumTakeProfitStopLossQuantity)
          : sumFilledQuantityStable,
        sumQuantityStable
      ) :
      takeAverage(
        Number(sumFilledUnrealizedQuantity.getValue()) > 0
          ? sumFilledUnrealizedQuantity.add(sumFirstSecondPlacedQuantity)
          : sumFilledQuantityStable,
        sumQuantityStable
      );

  const profitQuantity = Number(marketPriceDecimal.getValue()) === 0 || Number(sumFilledPrice.getValue()) === 0
    ? bigDecimalZero
    : counterEarning
      ? Number(valueSecond
        .divide(marketPriceDecimal)
        .round(data[0].counterRound, bigDecimal.RoundingModes.DOWN)
        .subtract(Number(sumFilledUnrealizedQuantity.getValue()) > 0 ? sumFilledUnrealizedQuantity : sumFilledQuantityStable).getValue()) >= 0 ?
        //if value more than 0 profit in counter currency
        valueSecond
          .divide(marketPriceDecimal)
          .round(data[0].counterRound, bigDecimal.RoundingModes.DOWN)
          .subtract(Number(sumFilledUnrealizedQuantity.getValue()) > 0 ? sumFilledUnrealizedQuantity : sumFilledQuantityStable)
          .multiply(marketPriceDecimal)
          .round(data[0].counterRound, bigDecimal.RoundingModes.DOWN) :
        //if value less than 0 loss in base currency
        valueSecond
          .divide(marketPriceDecimal)
          .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)
          .subtract(Number(sumFilledUnrealizedQuantity.getValue()) > 0 ? sumFilledUnrealizedQuantity : sumFilledQuantityStable)
          .round(data[0].counterRound, bigDecimal.RoundingModes.DOWN) :

      //baseCurrency calculation
      Number(valueSecond
        .divide(marketPriceDecimal)
        .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)
        .subtract(valueSecond.divide(sumFilledPrice))
        .getValue()) < data[0].baseQuantityStep &&
      Number(valueSecond
        .divide(marketPriceDecimal)
        .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)
        .subtract(valueSecond.divide(sumFilledPrice))
        .getValue()) >= 0 ?
        //if value more than 0 profit but less that baseStep in counter currency
        valueSecond
          .subtract(valueSecond.divide(marketPriceDecimal).round(data[0].baseRound, bigDecimal.RoundingModes.DOWN).multiply(marketPriceDecimal)) :
        Number(valueSecond
          .divide(marketPriceDecimal)
          .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)
          .subtract(valueSecond.divide(sumFilledPrice))
          .getValue()) < 0 ?
          valueSecond
            .divide(marketPriceDecimal)
            .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)
            .subtract(valueSecond.divide(sumFilledPrice)) :
          valueSecond
            .divide(marketPriceDecimal)
            .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)
            .subtract(Number(sumFilledUnrealizedQuantity.getValue()) > 0 ? sumFilledUnrealizedQuantity : sumFilledQuantityStable);


  const profitPercentSecond = Number(sumFilledQuantityStable.getValue()) === 0 || Number(sumFilledQuantityStable.add(sumFirstSecondPlacedQuantity).getValue()) === 0 ? bigDecimalZero.getValue() :
    counterEarning ?
      Number(profitQuantity.getValue()) > 0 ?
        //if value more than 0 profit in % for counter currency
        profitQuantity
          .divide(valueSecond)
          .multiply(new bigDecimal('100'))
          .round(2, bigDecimal.RoundingModes.DOWN).getValue() :
        //if value less than 0 loss in % for base currency
        profitQuantity
          .divide(sumFilledQuantityStable)
          .multiply(new bigDecimal('100'))
          .round(2, bigDecimal.RoundingModes.DOWN)
          .getValue() :
      //baseCurrency % calculation
      Number(profitQuantity.getValue()) >= data[0].baseQuantityStep ?
        profitQuantity
          .divide(sumFilledQuantityStable)
          .multiply(new bigDecimal('100'))
          .round(2, bigDecimal.RoundingModes.DOWN)
          .getValue() :
        Number(profitQuantity.getValue()) < data[0].baseQuantityStep && Number(profitQuantity.getValue()) >= 0 ?
          profitQuantity
            .divide(valueSecond)
            .multiply(new bigDecimal('100'))
            .round(2, bigDecimal.RoundingModes.DOWN)
            .getValue() :
          profitQuantity
            .divide(sumFilledQuantityStable)
            .multiply(new bigDecimal('100'))
            .round(2, bigDecimal.RoundingModes.DOWN)
            .getValue();

  // calculate 3-rd position
  const averageQuantityThird =
    Number(sumQuantity.getValue()) === 0 || Number(sumFirstSecondPlacedQuantity.getValue()) === 0 || Number(sumFirstSecondPlacedQuantity.getValue()) === 0 ?
      0 :
      counterEarning ?
        takeAverage(sumTakeProfitStopLossQuantity,
          Number(sumFilledUnrealizedQuantity.getValue()) > 0 ?
            sumFilledUnrealizedQuantity :
            sumFilledQuantityStable) :
        takeAverage(sumFirstSecondPlacedQuantity,
          Number(sumFilledUnrealizedQuantity.getValue()) > 0 ?
            sumFilledUnrealizedQuantity :
            sumFilledQuantityStable);


  const profitTakeProfitStopLossQuantity = Number(marketPriceDecimal.getValue()) === 0 || Number(sumFilledPrice.getValue()) === 0 ?
    bigDecimalZero :
    counterEarning ?
      valueThird.compareTo(new bigDecimal(valueFourth)) !== 0 ?
        Number(valueThird
          .subtract(new bigDecimal(valueFourth))
          .divide(marketPriceDecimal)
          .round(data[0].counterRound, bigDecimal.RoundingModes.DOWN)
          .subtract(valueThird.subtract(new bigDecimal(valueFourth)).divide(sumFilledPrice)).getValue()) >= 0 ?
          //if value more than 0 profit in % for counter currency
          valueThird
            .subtract(new bigDecimal(valueFourth))
            .divide(marketPriceDecimal)
            .round(data[0].counterRound, bigDecimal.RoundingModes.DOWN)
            .subtract(valueThird.subtract(new bigDecimal(valueFourth)).divide(sumFilledPrice))
            .multiply(marketPriceDecimal)
            .round(data[0].counterRound, bigDecimal.RoundingModes.DOWN) :
          //if value less than 0 profit in % for base currency
          valueThird
            .subtract(new bigDecimal(valueFourth))
            .divide(marketPriceDecimal)
            .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)
            .subtract(valueThird.subtract(new bigDecimal(valueFourth)).divide(sumFilledPrice))
            .round(data[0].counterRound, bigDecimal.RoundingModes.DOWN) :
        bigDecimalZero
          .round(data[0].counterRound, bigDecimal.RoundingModes.DOWN) :

      //base currency calculation
      Number(valueThird
        .subtract(new bigDecimal(valueFourth))
        .divide(marketPriceDecimal)
        .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)
        .subtract(valueThird.subtract(new bigDecimal(valueFourth))
          .divide(sumFilledPrice)).getValue()) < data[0].baseQuantityStep &&
      Number(valueThird
        .subtract(new bigDecimal(valueFourth))
        .divide(marketPriceDecimal)
        .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)
        .subtract(valueThird.subtract(new bigDecimal(valueFourth))
          .divide(sumFilledPrice)).getValue()) >= 0 ?
        //if value more than 0 but less than  baseStep
        valueThird
          .subtract(new bigDecimal(valueFourth))
          .subtract(valueThird
            .subtract(new bigDecimal(valueFourth)).divide(marketPriceDecimal).round(data[0].baseRound, bigDecimal.RoundingModes.DOWN).multiply(marketPriceDecimal)) :
        //if value less than  base currency step
        Number(valueThird
          .subtract(new bigDecimal(valueFourth))
          .divide(marketPriceDecimal)
          .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)
          .subtract(valueThird
            .subtract(new bigDecimal(valueFourth)).divide(sumFilledPrice))
          .getValue()) < 0 ?
          valueThird
            .subtract(new bigDecimal(valueFourth))
            .divide(marketPriceDecimal)
            .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)
            .subtract(valueThird
              .subtract(new bigDecimal(valueFourth)).divide(sumFilledPrice)) :
          valueThird
            .subtract(new bigDecimal(valueFourth))
            .divide(marketPriceDecimal)
            .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)
            .subtract(valueThird.subtract(new bigDecimal(valueFourth))
              .divide(sumFilledPrice))


  const profitTakeProfitStopLossValue = Number(profitTakeProfitStopLossQuantity.getValue());

  const profitTakeProfitStopLossPercent = Number(valueThird.getValue()) === 0 || Number(valueThird
    .subtract(new bigDecimal(valueFourth)).getValue()) === 0 ?
    bigDecimalZero :
    counterEarning ?
      valueThird.compareTo(new bigDecimal(valueFourth)) !== 0 ?
        Number(profitTakeProfitStopLossQuantity.getValue()) >= 0 ?
          //if value more than 0 profit in % for counter currency
          new bigDecimal(profitTakeProfitStopLossValue)
            .divide(valueThird
              .subtract(new bigDecimal(valueFourth)))
            .multiply(new bigDecimal('100'))
            .round(2, bigDecimal.RoundingModes.DOWN) :
          //if value less than 0 profit in % for base currency
          new bigDecimal(profitTakeProfitStopLossValue)
            .divide(sumFilledQuantityStable)
            .multiply(new bigDecimal('100'))
            .round(2, bigDecimal.RoundingModes.DOWN) :
        bigDecimalZero
          .round(2, bigDecimal.RoundingModes.DOWN) :

      Number(profitTakeProfitStopLossQuantity.getValue()) >= data[0].baseQuantityStep ?
        profitTakeProfitStopLossQuantity
          .divide(sumFilledQuantityStable)
          .multiply(new bigDecimal('100'))
          .round(2, bigDecimal.RoundingModes.DOWN) :
        Number(profitTakeProfitStopLossQuantity.getValue()) < data[0].baseQuantityStep && Number(profitTakeProfitStopLossQuantity.getValue()) > 0 ?
          profitTakeProfitStopLossQuantity
            .divide(valueThird.subtract(new bigDecimal(valueFourth)))
            .multiply(new bigDecimal('100'))
            .round(2, bigDecimal.RoundingModes.DOWN) :
          profitTakeProfitStopLossQuantity
            .divide(sumFilledQuantityStable)
            .multiply(new bigDecimal('100'))
            .round(2, bigDecimal.RoundingModes.DOWN)

  // calculate 4-th position
  const averageQuantityFourth = Number(sumQuantity.getValue()) === 0 || Number(sumTakeProfitStopLossQuantity.getValue()) === 0 ?
    0 :
    counterEarning ?
      takeAverage(sumFilledTakeProfitStopLossQuantity, sumTakeProfitStopLossQuantity) :
      sumTakeProfitStopLossQuantity.compareTo(sumFilledTakeProfitStopLossQuantity) === 0 ?
        100 :
        takeAverage(sumFilledTakeProfitStopLossQuantity.multiply(sumSecondFilledPrice).divide(sumFilledPrice), sumFirstSecondPlacedQuantity);


  const resultSumFilledTakeProfitStopLossQuantityAdditional = counterEarning ? sumBuyFilledTakeProfitStopLossCounterQuantity : sumFilledTakeProfitStopLossQuantity;

  const profitFilledTakeProfitStopLossQuantity = Number(marketPriceDecimal.getValue()) === 0 || Number(sumSecondFilledPrice.getValue()) === 0 || Number(sumFilledPrice.getValue()) === 0 || Number(new bigDecimal(valueFourth).divide(sumFilledPrice).getValue()) === 0 ? 0 :
    counterEarning ?
      Number(new bigDecimal(valueFourth)
        .divide(sumSecondFilledPrice)
        .subtract(new bigDecimal(valueFourth).divide(sumFilledPrice)).getValue()) >= 0 ?
        //if more than 0 but both amount of base currency are equal
        Number(new bigDecimal(valueFourth).divide(sumFilledPrice).getValue()) === 0 ? 0 :
          new bigDecimal(valueFourth)
            .divide(sumSecondFilledPrice)
            .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN).compareTo(new bigDecimal(valueFourth).divide(sumFilledPrice).round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)) !== 0 ?
            new bigDecimal(valueFourth)
              .divide(sumSecondFilledPrice)
              .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)
              .subtract(new bigDecimal(valueFourth).divide(sumFilledPrice))
              .multiply(sumSecondFilledPrice)
              .getValue() :
            new bigDecimal(valueFourth)
              .subtract(new bigDecimal(valueFourth)
                .divide(sumSecondFilledPrice)
                .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)
                .multiply(sumSecondFilledPrice))
              .getValue() :
        new bigDecimal(valueFourth)
          .divide(sumSecondFilledPrice)
          .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)
          .subtract(new bigDecimal(valueFourth).divide(sumFilledPrice))
          .getValue() :
      //base currency
      Number(new bigDecimal(valueFourth)
        .divide(sumSecondFilledPrice)
        .subtract(new bigDecimal(valueFourth)
          .divide(sumFilledPrice))
        .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)
        .getValue()) >= data[0].baseQuantityStep ?
        //if profit more than base currency step
        new bigDecimal(valueFourth)
          .divide(sumSecondFilledPrice)
          .subtract(new bigDecimal(valueFourth)
            .divide(sumFilledPrice))
          .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)
          .getValue() :
        //if profit less than base currency step
        (Number(new bigDecimal(valueFourth)
            .divide(sumSecondFilledPrice)
            .subtract(new bigDecimal(valueFourth)
              .divide(sumFilledPrice))
            .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)
            .getValue()) < data[0].baseQuantityStep &&
          Number(new bigDecimal(valueFourth)
            .divide(sumSecondFilledPrice)
            .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)
            .subtract(new bigDecimal(valueFourth)
              .divide(sumFilledPrice))
            .getValue()) >= 0) ?
          new bigDecimal(valueFourth)
            .subtract(new bigDecimal(valueFourth)
              .divide(sumFilledPrice)
              .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)
              .multiply(sumSecondFilledPrice))
            .getValue() :
          new bigDecimal(valueFourth)
            .divide(sumSecondFilledPrice)
            .round(data[0].baseRound, bigDecimal.RoundingModes.DOWN)
            .subtract(new bigDecimal(valueFourth)
              .divide(sumFilledPrice))
            .getValue();

  const profitFilledTakeProfitStopLossValue = Number(profitFilledTakeProfitStopLossQuantity);

  const profitFilledTakeProfitStopLossValuePercent = Number(sumFirstSecondPlacedQuantity.getValue()) === 0 || Number(sumTakeProfitStopLossQuantity.getValue()) === 0 || Number(sumFilledTakeProfitStopLossQuantity.getValue()) === 0 || Number(new bigDecimal(valueFourth).getValue()) === 0 ?
    bigDecimalZero :
    counterEarning ?
      Number(profitFilledTakeProfitStopLossQuantity) >= 0 ?
        new bigDecimal(profitFilledTakeProfitStopLossQuantity)
          .divide(new bigDecimal(valueFourth))
          .multiply(new bigDecimal('100'))
          .round(2, bigDecimal.RoundingModes.DOWN) :
        new bigDecimal(profitFilledTakeProfitStopLossQuantity)
          .divide(new bigDecimal(valueFourth).divide(sumFilledPrice))
          .multiply(new bigDecimal('100'))
          .round(2, bigDecimal.RoundingModes.DOWN) :
      //base currency
      Number(profitFilledTakeProfitStopLossQuantity) >= data[0].baseQuantityStep ?
        new bigDecimal(profitFilledTakeProfitStopLossQuantity)
          .divide(new bigDecimal(valueFourth).divide(sumFilledPrice))
          .multiply(new bigDecimal('100'))
          .round(2, bigDecimal.RoundingModes.DOWN) :
        new bigDecimal(profitFilledTakeProfitStopLossQuantity)
          .divide(new bigDecimal(valueFourth).divide(sumFilledPrice))
          .multiply(new bigDecimal('100'))
          .round(2, bigDecimal.RoundingModes.DOWN);


  //Display positions
  const showFirstPosition = Number(valueFirst.getValue()) > 0;
  const showSecondPosition = counterEarning
    ? sumTakeProfitStopLossQuantity.compareTo(sumFilledQuantityStable) !== 0 :
    Number(sumFilledQuantityStable.getValue()) > 0 && Number(sumTakeProfitStopLossQuantity.getValue()) === 0;
  const showThirdPosition = counterEarning
    ? sumFilledTakeProfitStopLossQuantity.compareTo(sumTakeProfitStopLossQuantity) !== 0
    : sumTakeProfitStopLossQuantity.compareTo(sumFilledTakeProfitStopLossQuantity) !== 0;
  const showFourthPosition = sumFilledTakeProfitStopLossQuantity.compareTo(bigDecimalZero) !== 0;
  const showCssLine = [showFirstPosition, showSecondPosition, showThirdPosition, showFourthPosition].filter(Boolean).length > 1;


  useEffect(() => {

    if (Number(data[0].secondTakeProfitPlacedPrice) > 0) {
      setDataTakeProfitStopLossQuantity(() => 'secondTakeProfitPlacedQuantity');
      setDataFirstSecondPlacedQuantity(() => 'secondFirstTakeProfitPlacedQuantity');
    } else if (Number(data[0].secondStopLossPlacedPrice) > 0) {
      setDataTakeProfitStopLossQuantity(() => 'secondStopLossPlacedQuantity');
      setDataFirstSecondPlacedQuantity(() => 'secondFirstStopLossPlacedQuantity');
    }

    if (Number(data[0].secondTakeProfitFilledPrice) > 0) {
      setDataFilledTakeProfitStopLossQuantity(() => 'secondTakeProfitFilledQuantity');
      setDataBuyFilledTakeProfitStopLossCounterQuantity(() => 'secondFirstTakeProfitFilledCounterQuantity');
      setDataSecondFilledPrice(() => 'secondTakeProfitFilledPrice');
    } else if (Number(data[0].secondStopLossFilledPrice) > 0) {
      setDataFilledTakeProfitStopLossQuantity(() => 'secondStopLossFilledQuantity');
      setDataBuyFilledTakeProfitStopLossCounterQuantity(() => 'secondFirstStopLossFilledCounterQuantity');
      setDataSecondFilledPrice(() => 'secondStopLossFilledPrice');
    }
  }, [counterEarning, data]);

  return {
    valueFirst,
    valueSecond,
    valueThird,
    valueFourth,
    averageQuantitySecond,
    profitQuantity,
    profitPercentSecond,
    averageQuantityThird,
    profitTakeProfitStopLossQuantity,
    profitTakeProfitStopLossValue,
    profitTakeProfitStopLossPercent,
    averageQuantityFourth,
    profitFilledTakeProfitStopLossQuantity,
    profitFilledTakeProfitStopLossValue,
    profitFilledTakeProfitStopLossValuePercent,
    resultSumFilledTakeProfitStopLossQuantityAdditional,
    showFirstPosition,
    showSecondPosition,
    showThirdPosition,
    showFourthPosition,
    showCssLine
  } as CalculateMarketDataResult;
}
