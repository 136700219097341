import React, { useEffect, useState } from 'react';
import { Col, Row, Stack } from "react-bootstrap";
import { toast } from "react-toastify";

import '../components/Bot/Bot.scss'
import { Header } from '../components/Header';
import { AlertsTable } from "../components/AlertsTable/AlertsTable";
import useTitle from '../hooks/useTitle';
import useAlertSeen from "../hooks/useAlertSeen";
import { AlertsListTypeContent } from "../types/types";
import { client } from "../api/fetchClient";


export const AlertsPage = () => {
  const [alertsPrice, setAlertsPrice] = useState(0);
  const [alertExecuted, setAlertExecuted] = useState<AlertsListTypeContent>();
  const [currentMarket, setCurrentMarket] = useState('binance');
  useTitle('CopyMaster – your assistant in crypto-trading');
  const {setAlertsNotSeenList} = useAlertSeen();

  const scrollToTop = () => {
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    const getAlertsCount = async () => {
      try {
        const loadedData = await client.get<number[]>('/api/alert/not-seen-id-list?market=BINANCE');
        setAlertsNotSeenList(loadedData);
      } catch (error) {
        toast.error(`${error}`);
      }
    };

    getAlertsCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header/>

      <main className='position-relative bot bot-page'
            style={{maxWidth: '80rem', margin: '0 auto', padding: '0.5rem 0.5rem 4.5rem 0.5rem'}}>
        <section className='mt-5'>
          <Stack direction='vertical' className='d-flex justify-content-center w-100'>
            <Row className='mt-5'>
              <Col className='mt-3'>
                <h1 className='roboto-bold'>Alerts</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <AlertsTable alertsPrice={alertsPrice} currentMarket={currentMarket} alertExecuted={alertExecuted}/>
              </Col>
            </Row>
          </Stack>
        </section>
      </main>
    </>
  );
};

export default AlertsPage;